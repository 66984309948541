.App {
  text-align: center;
  color: #1b0930;
  background-color: #fff;
  font-size: calc(10px + 1vmin);
}

.App-QRCode {
  width: 240px;
  height: 93px;
}

.App-header {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

/* https://codepen.io/slimsmearlapp/pen/HJgFG */
button {
  width: 130px;
  height: 40px;
  background: linear-gradient(to bottom, #4eb5e5 0%, #389ed5 100%); /* W3C */
  border: none;
  border-radius: 5px;
  position: relative;
  border-bottom: 4px solid #2b8bc6;
  color: #fbfbfb;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  font-size: 15px;
  text-indent: 5px;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-align: right;
  padding-right: 12px;
  box-sizing: border-box;
  transition: all 0.4s;

  /* Just for presentation */
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
button:active {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  top: 1px;
}

button:hover {
  transform: scale(1.05);
}

button::after {
  content: '';
  width: 0;
  height: 0;
  display: block;
  border-top: 20px solid #187dbc;
  border-bottom: 20px solid #187dbc;
  border-right: 16px solid transparent;
  border-left: 20px solid #187dbc;
  position: absolute;
  opacity: 0.6;
  left: 0;
  top: 0;
  border-radius: 5px 0 0 5px;
}

a {
  color: inherit;
}
